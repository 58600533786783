$bleu_nuit : #00004D;
$or : #D7C378;
$corail : #FF5757;
$bleu_olympique : #0282C8;
$jaune_olympique : #ebb53e;
$vert_olympique : #00A651;
$rouge_olympique : #ED354F;
$noir : #000000;
$blanc : #FFFFFF;
$graphique1 : #4d4d4d;
$graphique2: #bfbfbf;

@font-face {
    font-family: "thin";
    src: url(../assets/fonts/Poppins-Thin.ttf);
}

@font-face {
    font-family: "regular";
    src: url(../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: "semibold";
    src: url(../assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: "medium";
    src: url(../assets/fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: "extra";
    src: url(../assets/fonts/Poppins-ExtraBold.ttf);
}

$thin: "thin", sans-serif;
$regular: "regular", sans-serif;
$semibold: "semibold", sans-serif;
$medium: "medium", sans-serif;
$extra: "extra", sans-serif;

* {
    margin: 0;
    padding: 0;
}