$black: #000000;
$grey: #cccccc;



.clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
		visibility: hidden;
		height: 0;
	}
}

.over_register {
	position: relative;
	width: 100%;
	height: 100vh;
	//top: 0;
	background: linear-gradient(to bottom, #bfbfbf, #bfbfbf 100%);
	overflow: hidden;
}


.back_register {
	width: 100%;
	height: 100vh;
	background-image: url(../../assets/img/login_back.jpg);
	background-size: cover;
	transform: scale(1.1);
	position: absolute;
	background-size: 100%;
	background-repeat: no-repeat;
}

.form_wrapper {

	border-radius: 10px;
	background: #fff;
	width: 400px;
	max-width: 100%;
	box-sizing: border-box;
	padding: 25px;
	margin: 8% auto 0;
	position: relative;
	z-index: 1;
	border-top: 5px solid $rouge_olympique;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	-webkit-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
	-webkit-transition: none;
	transition: none;
	-webkit-animation: expand 0.8s 0.6s ease-out forwards;
	animation: expand 0.8s 0.6s ease-out forwards;
	opacity: 0;

	h2 {

		font-family: $regular;
		font-size: 25px;
		line-height: 1.5em;
		margin: 0;
	}

	.title_container {
		text-align: center;
		padding-bottom: 15px;

	}

	label {
		font-size: 12px;
	}

	.row {
		margin: 10px -15px;

		>div {
			padding: 0 15px;
			box-sizing: border-box;
		}
	}

	.input_field {

		position: relative;
		margin-bottom: 20px;
		-webkit-animation: bounce 0.6s ease-out;
		animation: bounce 0.6s ease-out;

	}

	.textarea_field {
		>span {
			>i {
				padding-top: 10px;

			}
		}
	}

	input {

		&[type="text"],
		&[type="email"],
		&[type="password"] {
			border-radius: 10px;
			width: 100%;
			padding: 8px 10px 9px 10px;
			height: 35px;
			border: 1px solid $bleu_olympique;
			box-sizing: border-box;
			outline: none;
			-webkit-transition: all 0.30s ease-in-out;
			-moz-transition: all 0.30s ease-in-out;
			-ms-transition: all 0.30s ease-in-out;
			transition: all 0.30s ease-in-out;
			font-family: $medium;

		}

		&[type="text"]:hover,
		&[type="email"]:hover,
		&[type="password"]:hover {
			background: #fafafa;

		}

		&[type="text"]:focus,
		&[type="email"]:focus,
		&[type="password"]:focus {
			-webkit-box-shadow: 0 0 2px 1px $vert_olympique;
			-moz-box-shadow: 0 0 2px 1px $vert_olympique;
			box-shadow: 0 0 2px 1px $vert_olympique;
			border: 1px solid $vert_olympique;
			background: #fafafa;

		}

		&[type="submit"] {
			border-radius: 10px;
			background: $jaune_olympique;
			height: 35px;
			line-height: 35px;
			width: 100%;
			border: none;
			outline: none;
			cursor: pointer;
			color: #fff;
			font-size: 1.1em;
			font-family: $regular;
			margin-top: 18px;
			margin-bottom: 10px;
			-webkit-transition: all 0.30s ease-in-out;
			-moz-transition: all 0.30s ease-in-out;
			-ms-transition: all 0.30s ease-in-out;
			transition: all 0.30s ease-in-out;

			&:hover {
				background: darken($jaune_olympique, 7%);
			}

			&:focus {
				background: darken($jaune_olympique, 7%);
			}
		}
	}
}

.form_container {
	.row {
		.col_half.last {
			border-left: 1px solid $grey;
		}
	}
}


@-webkit-keyframes check {
	0% {
		height: 0;
		width: 0;
	}

	25% {
		height: 0;
		width: 7px;
	}

	50% {
		height: 20px;
		width: 7px;
	}
}

@keyframes check {
	0% {
		height: 0;
		width: 0;
	}

	25% {
		height: 0;
		width: 7px;
	}

	50% {
		height: 20px;
		width: 7px;
	}
}

@-webkit-keyframes expand {
	0% {
		-webkit-transform: scale3d(1, 0, 1);
		opacity: 0;
	}

	25% {
		-webkit-transform: scale3d(1, 1.2, 1);
	}

	50% {
		-webkit-transform: scale3d(1, 0.85, 1);
	}

	75% {
		-webkit-transform: scale3d(1, 1.05, 1);
	}

	100% {
		-webkit-transform: scale3d(1, 1, 1);
		opacity: 1;
	}
}

@keyframes expand {
	0% {
		-webkit-transform: scale3d(1, 0, 1);
		transform: scale3d(1, 0, 1);
		opacity: 0;
	}

	25% {
		-webkit-transform: scale3d(1, 1.2, 1);
		transform: scale3d(1, 1.2, 1);
	}

	50% {
		-webkit-transform: scale3d(1, 0.85, 1);
		transform: scale3d(1, 0.85, 1);
	}

	75% {
		-webkit-transform: scale3d(1, 1.05, 1);
		transform: scale3d(1, 1.05, 1);
	}

	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
		opacity: 1;
	}
}


@-webkit-keyframes bounce {
	0% {
		-webkit-transform: translate3d(0, -25px, 0);
		opacity: 0;
	}

	25% {
		-webkit-transform: translate3d(0, 10px, 0);
	}

	50% {
		-webkit-transform: translate3d(0, -6px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, 2px, 0);
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes bounce {
	0% {
		-webkit-transform: translate3d(0, -25px, 0);
		transform: translate3d(0, -25px, 0);
		opacity: 0;
	}

	25% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	50% {
		-webkit-transform: translate3d(0, -6px, 0);
		transform: translate3d(0, -6px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, 2px, 0);
		transform: translate3d(0, 2px, 0);
	}

	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@media (max-width: 600px) {
	.form_wrapper {
		.col_half {
			width: 100%;
			float: none;
		}
	}

	.bottom_row {
		.col_half {
			width: 50%;
			float: left;
		}
	}

	.form_container {
		.row {
			.col_half.last {
				border-left: none;
			}
		}
	}

	.remember_me {
		padding-bottom: 20px;
	}
}