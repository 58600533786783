.back_login {
    width: 100%;
    height: 100vh;
    background-image: url(../../assets/img/login_back.jpg);
    background-size: cover;
    transform: scale(1.1);
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;

}

.over_login {
    background: linear-gradient(to bottom, #bfbfbf, #bfbfbf 100%);
}


.link_responsive {
    position: absolute;
    background-color: #bfbfbf;
    width: 40%;
    height: 10vh;
    filter: blur(100px);
    z-index: 30;
    margin-top: 20%;
    margin-left: -30%;
}

//@media screen and (max-width: 900px) {}

.over_login {
    position: absolute;
    width: 100%;
    height: 100vh;
    filter: blur(0px);
    transform: scale(1.1);
    overflow: hidden;
}

.over_the_over {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.over_contain {
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.container_login {
    position: relative;
    width: 95%;
    margin-left: 2.5%;
    padding-top: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.title_login {
    position: relative;
    width: 55%;
}

.title_login img {
    width: 60%;
    height: auto;
    margin-left: 5%;
}

.title_login h1 {
    background-image: linear-gradient(-225deg,
            $jaune_olympique 0%,
            $bleu_olympique 29%,
            $rouge_olympique 67%,
            $vert_olympique 100%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: $blanc;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    font-size: 5vw;
    font-family: $extra;
    margin-left: 5%;
    margin-top: 2%;
    line-height: 80%;
    font-style: italic;
}

@keyframes textclip {
    to {
        background-position: 200% center;
    }
}

.title_login p {
    font-family: $thin;
    color: $blanc;
    font-weight: bold;
    margin-top: 3%;
    font-size: 1.5vw;
    margin-left: 5%;
}

.just_for_title {

    width: 75%;
}

.form_login {
    position: relative;
    width: 40%;
    border-radius: 8px;
    background-color: $blanc;
    //margin-right: 10%;
    transition: 0.5s;
}

/*
@media screen and (max-width: 900px) {

    .just_for_title {
        width: 50%;
    }

    .form_login {
        width: 70%;
        margin-left: 10%;
    }
}*/

.form_login h1 {
    font-family: $medium;
    font-style: italic;
    margin-left: 5%;
    padding-top: 6%;
    font-size: 1.5vw;
}

.form_login input {
    margin-left: 5%;
    margin-top: 1%;
    font-size: 1vw;
    font-family: $thin;
    font-weight: bold;
    padding-left: 3%;
    padding-top: 1.5%;
    padding-bottom: 0.5vw;
    border-radius: 8px;
    width: 86.5%;
    border: none;
}

.form_login:hover {
    transform: scale(1.1);
}



.button_login button {
    border-radius: 8px;
    padding: 1%;
    font-family: $thin;
    font-weight: bold;
    color: $blanc;
    border: none;
    font-size: 1.1vw;
    transition: 0.2s;
    margin-bottom: 2%;
}

.button_login button:hover {
    transform: scale(1.2);
}

.button_login button:active {
    opacity: 0.2;
}

/*
@media screen and (max-width: 900px) {

    .button_login button {
        margin-bottom: 2%;
    }

    .title_login img {
        width: 60%;
        height: auto;
        margin-left: 23%;
    }

    .title_login p {
        margin-left: 28%;
    }

    .just_for_title {
        margin-left: 15%;
    }
}*/

#login_button {
    background-color: $bleu_olympique;
}

#register_button {
    background-color: $rouge_olympique;
}

#email_input {
    background-color: $jaune_olympique;
    transition: 0.5s;
}

#password_input {
    background-color: $vert_olympique;
    transition: 0.5s;
}

#responsive_margin {
    margin-bottom: 5%;
}

#password_input:hover,
#email_input:hover {
    background-color: #bfbfbf;
}

.button_login {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 90%;
    padding-left: 5%;
    margin-top: 10%;
}

.error_mssg_login {
    margin-left: 3.2%;
    margin-top: 5%;
    width: 80%;
    font-size: 1.5vw;
    font-family: $thin;
    font-weight: bold;
    color: $rouge_olympique;
}