.pre_container_error footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.container_error {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-evenly;
    
}


.container_error .title_login {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
}

.error_message {
    font-weight: bold;
    margin: auto;
}

.error_message h1 {
    font-family: "medium", sans-serif;
    color: $rouge_olympique;
    font-size: 4vw;
}

.error_message p {
    font-family: "thin", sans-serif;
    text-align: center;
    transition: .2s;
}

.error_message a {
    color: $noir;
}

.error_message p:hover {
    font-size: 2.5vw;
    transition: .2s;
}