.container_home_page {
    width: 80%;
    margin-left: 10%;
    // height: 100vh;
    //overflow: hidden;
}

.nav_bar {
    height: 11vw;
    width: 100%;
    background-color: $bleu_nuit;
    position: sticky;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    z-index: 20;
}


.logo_site {
    //background-color: violet;
    width: 50%;
}

.logo_site img {
    width: 35vw;
    height: auto;
    padding-left: 5%;
    padding-top: 2%;
}

.but_nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 50%;
    padding-top: 0%;
    //background-color: red;
}

#logo_nav {
    padding-top: 2%;
    border-bottom-right-radius: 15px;
}

.but_nav h1 {
    color: $blanc;
    font-family: $medium;
    text-decoration: underline;
    font-size: 2vw;
}

.but_nav button {
    color: $blanc;
    font-family: $medium;
    text-decoration: underline;
    font-size: 2vw;
    background-color: transparent;
    border: none;
    transition: 0.2s;
}

.but_nav button:hover {
    transform: scale(1.1);
}

#ath {
    width: 100%;
    border-radius: 8px;
    border: none;
    padding-left: 5%;
    margin-left: 5%;
    background-color: $graphique2;
    font-family: $thin;
    font-weight: bold;
    font-size: 2vw;
    margin-right: 3%;
}

.slider_site {
    position: relative;
    margin-top: 5%;
    margin-bottom: 10%;
}

/*

.menu {
    position: absolute;
    list-style-type: none;
    margin: 1% 0;
    padding: 0;
    //border: 1px solid $graphique1;
    border-radius: 8px;
    width: 20vw;
    font-family: $medium;
}

.menu>li {
    margin: 0;
    border-radius: 8px;
    background-color: $blanc;
    font-size: 1vw;
}

.menu>li:hover {
    background-color: $graphique2;
}

.menu>li>button {
    width: 100%;
    height: 100%;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    padding: 1%;
    //margin: 0;
    font: inherit;
    cursor: pointer;
}

#drop_custom {
    position: relative;
    background-color: $blanc;
    border: none;
    border-radius: 8px;
    padding: 1%;
    font-size: 1.5vw;
    font-family: $medium;
    margin-left: 2%;
}
*/

.dropbtn {
    background-color: $blanc;
    color: white;
    border-radius: 8px;
    padding: 3%;
    font-size: 2vw;
    border: none;
    cursor: pointer;
    color: $graphique1;
    font-family: $medium;
}

.dropdown {
    position: relative;
    margin-left: 3%;
    //display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: $graphique2;
    border-radius: 8px;
    width: 15vw;
    // position: absolute;
    //box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: $noir;
    font-family: $medium;
    padding: 8%;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    border-radius: 8px;
    background-color: $blanc
}

.dropdown:hover .dropdown-content {

    display: block;
}

.search {
    width: 58%;
    //height: 20vh;
    //background-color: red;
    display: flex;
    flex-wrap: nowrap;
    //justify-content: center;
    //margin-left: 10%;
    //margin-top: 5%;
    //margin-bottom: 5%;
}

.filter_nav {
    padding: 1%;
    width: 99%;
    background-color: $graphique1;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    margin-bottom: 8%;
    //background-color: blue;
    //justify-content: center;
}

.footer {
    /*
    bottom: 1000;
    position: relative;
    z-index: 30;*/
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-top: 120px;
    background-color: white;
}

.footer a {
    flex-basis: 30%;
    text-align: center;
    padding: 10px;
}

.footer img {
    height: 70px;
    object-fit: contain;
}

.smaller {
    height: 40px !important;
}